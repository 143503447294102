<template>
    <div class="d-flex px-3" style="gap: 10px">
        <div id="posts" style="width: 900px;overflow: auto; height: calc(100vh - 95px);">

            <template v-for="post in posts">
                <div class="post my-3 py-3 white rounded" @click="selectPost(post)" :key="post.id">
                    <div class="header px-3">
                        <v-list-item>
                            <v-list-item-avatar color="#16426D">
                                <img :src="post.resident.avatarSrc" v-if="post.resident.avatarSrc.length > 0" />
                                <span class="white--text" v-else>{{ post.resident.firstName[0] + post.resident.lastName[0] }}</span>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>{{ post.resident.fullName }}</v-list-item-title>
                                <v-list-item-subtitle class="caption">{{ timeAgo( post.dateCreated ) }}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <!-- <v-btn icon>
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn> -->
                            </v-list-item-action>
                        </v-list-item>
                    </div>
                    <div class="content mb-2">
                        <div class="text px-7">
                            {{ post.message }}
                        </div>

                        <div class="gallery d-flex ga-2" style="width: 100%;" v-if="post.gallery.length > 0">
                            <template v-for="img in post.gallery">
                                <v-img :src="img.url.medium" width="100%" aspect-ratio="1" cover :key="img.id" />
                            </template>
                        </div>
                    </div>

                    <v-divider class="mt-4 mb-2"></v-divider>

                    <div class="px-3 pt-1 grey--text text-right">
                        {{ post.likes.length }} likes | {{ post.comments.length }} comments
                    </div>
                </div>
            </template>

        </div>

        <!-- selected post information -->
        <div style="min-width: 500px; height: calc(100vh - 95px);">
            <div class="d-flex flex-column align-center justify-center fill-height" v-if="selectedPost == null">
                <h2>Select a post</h2>
                <div class="grey--text">Click on a post to view more information about the post</div>
            </div>
            <div v-else>
                <h3 class="title mt-3">Resident</h3>

                <v-row>
                    <v-col cols="3">
                        <v-avatar color="#16426D" tile size="100" class="rounded-lg">
                            <img :src="selectedPost.resident.avatarSrc" v-if="selectedPost.resident.avatarSrc.length > 0" />
                            <span class="white--text" v-else>{{ selectedPost.resident.firstName[0] + selectedPost.resident.lastName[0] }}</span>
                        </v-avatar>
                    </v-col>

                    <v-col cols="9">
                        <h4>{{ selectedPost.resident.fullName }}</h4>
                        <div class="caption mb-2">
                            <v-icon size="16">mdi-phone</v-icon>
                            <span>{{ formatPhoneNum( selectedPost.resident.phone ) }}</span>
                            |
                            <v-icon size="16">mdi-email</v-icon>
                            <span>{{ selectedPost.resident.email }}</span>
                        </div>
                        <div class="d-flex" style="gap: 10px; justify-content: space-evenly">
                            <div class="grey lighten-2 rounded px-3 py-1 text-center" style="width: 100%">
                                <div><b>{{ selectedPost.resident.locations[0].unit.unitNumber }}</b></div>
                                <div class="caption">Unit</div>
                            </div>
                            <div class="grey lighten-2 rounded px-3 py-1 text-center" style="width: 100%">
                                <div><b>{{ selectedPost.resident.locations[0].unit.buildingType.name }}</b></div>
                                <div class="caption">Type</div>
                            </div>
                            <div class="grey lighten-2 rounded px-3 py-1 text-center" style="width: 100%">
                                <div><b>{{ formatMoney( selectedPost.resident.account.balance ) }}</b></div>
                                <div class="caption">Balance</div>
                            </div>
                        </div>
                    </v-col>
                </v-row>

                <v-expansion-panels v-model="panel" flat class="my-6">
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <b>Comments</b>
                            <template v-slot:actions>
                                {{ selectedPost.comments.length }}
                            </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <template v-for="comment in selectedPost.comments">
                                <div class="d-flex comment mt-3 mb-5" style="gap: 10px;" :key="comment.id">
                                    <v-avatar color="#16426D">
                                        <img :src="comment.resident.avatarSrc" v-if="comment.resident.avatarSrc.length > 0" />
                                        <span class="white--text" v-else>{{ comment.resident.firstName[0] + comment.resident.lastName[0] }}</span>
                                    </v-avatar>

                                    <div class="rounded-lg px-3 py-2 grey lighten-3" style="background-color: #16426D; width: 100%;">
                                        <div>{{ comment.comment }}</div>
                                        <div class="caption text-right">{{ timeAgo( comment.dateCreated ) }}</div>
                                    </div>
                                </div>
                            </template>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                        <v-expansion-panel-header disable-icon-rotate>
                            <b>Likes</b>
                            <template v-slot:actions>
                                {{ selectedPost.likes.length }}
                            </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <div class="grey lighten-3 text-center rounded py-3">
                                More info coming soon
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';

export default {
    data: () => ({
        selectedPost        : null,
        panel               : [],
    }),
    computed: {
        ...mapGetters({
            posts       : 'wall/posts',
        })
    },
    mounted() {
        this.loadPosts();

        const el = document.getElementById('posts');
        el.addEventListener('scroll', this.processScroll);
    },
    methods: {
        ...mapActions({
            loadPosts   : 'wall/load'
        }),
        selectPost( post ) {
            this.selectedPost = post;
            this.panel = [];
        },
        timeAgo( date ) {
            return moment( date ).fromNow();
        },
        processScroll() {
            const el = document.getElementById('posts');
            if( el.scrollTop + el.clientHeight >= el.scrollHeight ) {
                this.loadPosts();
            }
        }
    },
    beforeDestoy() {
        const el = document.getElementById('posts');
        el.removeEventListener('scroll', this.processScroll);
    }
}
</script>

<style lang="scss" scoped>

</style>